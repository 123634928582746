import type { FC, PropsWithChildren } from "preact/compat";
import { isRingPackSelected } from "../model";
import s from "./CardsSliderOrRingPicture.module.scss";
import { WalletImagesSlider } from "../WalletImagesSlider";
import { LimitedFeatures } from "../LimitedFeatures";
import type { GetImageResult } from "astro";

interface Props extends PropsWithChildren {
  limitedCaptionText: string;
  cardsImages: Record<
    string,
    { images: GetImageResult[]; preview: GetImageResult }
  >;
  features: Record<string, string>;
  usaFeatures: Record<string, string>;
}

export const CardsSliderOrRingPicture: FC<Props> = ({
  children,
  cardsImages,
  features,
  usaFeatures,
  limitedCaptionText,
}) =>
  isRingPackSelected.value ? (
    <>{children}</>
  ) : (
    <div className={s.slider__wrapper}>
      <WalletImagesSlider images={cardsImages} />
      <LimitedFeatures
        limitedCaptionText={limitedCaptionText}
        features={features}
        usaFeatures={usaFeatures}
      />
    </div>
  );
